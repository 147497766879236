/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { Link, Outlet, useHref, useLocation, useNavigate, useParams } from "react-router-dom"
//import { Context } from "../../contexts/general"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import http from "../../../libs/http"

import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { Divider } from "@mui/material"

import statusList from "../data/invoiceStatus"
import localizeDate from "../../scripts/localizeDate"

//console.log("PSK:",process.env.REACT_APP_STRIPE_PSK)
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PSK)

function Payment(){
	const baseDir = window.location.href.slice(0,window.location.href.indexOf("/payment/"))
	const location = useLocation()
	const currPath = location.pathname.slice(location.pathname.lastIndexOf("/")+1)
	console.log({baseDir,currPath})
	const [loading,setLoading] = useState(true)
	const [data,setData] = useState()
	const {id} = useParams()
	const navigate = useNavigate()
	const updatePaymentState = (stt)=>{
		setData(data=>{
			data.stt = stt
			return {...data}
		})
	}
	useEffect(()=>{
		if(loading && !data){
			http.get({url:`subscriptions/payment/${id}`,auth:true}).then(res=>{
				if(res.status === 200){
					const payment = res.data
					if(payment.csk){
						console.log("CSK present")
						setLoading(false)
						return setData(payment)
					}
					http.post({url:"subscriptions/initStripe",body:{_id:id},auth:true}).then(res=>{
						setLoading(false)
						if(res.status === 200){
							payment.csk = res.data.csk
							setData(payment)
						}
					})
				}
			})
		}
	},[loading,data])
	//if(data && data.csk) return <Elements options={{clientSecret: data.csk, appearance, loader} stripe={stripePromise}}>
	console.log({data})
	return <Box component={Paper} sx={{m:2, overflow:"hidden"}}>
		<Box sx={{display:"flex",flexDirection:"column"}}>
			{loading && <CircularProgress/>}
			{data && <Box>
				<Typography sx={{backgroundColor:"primary.main",color:"white"}} fontFamily="monospace" fontWeight={500} p={1} variant="h6">Pago {id.toUpperCase()}</Typography>
				<Box component={Paper} boxShadow={3} sx={{display:"flex",flexDirection:"column",width:"calc(100% - 2rem)",justifySelf:"center", maxWidth:"38rem",m:2,"> div":{display:"flex",flexDirection:"row",justifyContent:"space-between",mx:8,"> p:nth-of-type(1)":{fontWeight:600},"> p:nth-of-type(2)":{fontFamily:"monospace"}}}}>
					<Box><Typography>Deuda:</Typography><Typography>${data.q$.toFixed(2)}</Typography></Box>
					<Divider/>
					<Box><Typography>Estado:</Typography><Typography>{statusList.get(data.stt)}</Typography></Box>
					<Divider/>
					<Box><Typography>Fecha:</Typography><Typography>{localizeDate(data.cAt," ＠")}</Typography></Box>
				</Box>
				{currPath === id && (data.stt === 0 || data.stt === 1) && <Button sx={{mb:2}} variant="contained" onClick={()=>{navigate("checkout")}}>Pagar con Stripe</Button>}
				<Box sx={{mb:2}}><Typography>Para otros métodos de pago contáctanos aquí:</Typography>
				<Link to="https://wa.link/z6ybva" target="_blank" rel="noopener">https://wa.link/z6ybva</Link>
				</Box>
			</Box>}
		</Box>
		{data && data.csk && <Box sx={{p: currPath === id ? 0 : 2}}><Elements options={{clientSecret: data.csk}} stripe={stripePromise}><Outlet context={{baseDir,id,updatePaymentState}}/></Elements></Box>}
	</Box>
}
export default Payment