import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Context from './contexts/general'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@mui/icons-material'
import ThemeProvider from "@mui/material/styles/ThemeProvider"
import createTheme from "@mui/material/styles/createTheme"
const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme({
	components:{
		MuiTooltip:{
			styleOverrides: {
				tooltip:{
					backgroundColor: "#101935"
				},
				arrow:{
					color: "#101935"
				}
			}
		}
	},
	palette:{
		primary:{
			main: "#101935",
			light: "#9AD4D6"
		},
		lightGreen:{
			A200: "#b2ff59"
		}
	}
})
root.render(
	<Context.Provider>
		<ThemeProvider theme={theme}>
    	<App/>
		</ThemeProvider>
	</Context.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
