import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

function Main(){
	const location = useLocation()
	const navigate = useNavigate()
	const path = location.pathname
	//console.log("loren imsup dolro si amte")
	useEffect(()=>{
		//console.log(path)
		if(!path || path === "/"){
			//console.log("redirecting")
			return navigate("web",{replace:true,state:{from: path,msg:"ok"}})
		}
	})
	//return <></>
	return <Outlet/>
}
export default Main;