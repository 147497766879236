import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js"
import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import http from "../../../libs/http";

function StripeCheckout(){
	const stripe = useStripe()
  const elements = useElements()
	const data = useOutletContext()
	console.log({outletData: data})
	const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
	const [error,setError] = useState()
	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		try {
			const res = await http.post({url:"subscriptions/onPay",body:{_id:data?.id},auth:true}).then()
			if(res.status !== 200 && res.status !== 404){
				//if(res.status === 404){}// return setError([0,"El pago ya está en proceso o ya no está disponible"])}
				setIsLoading(false);
				return setError([1,"Ocurrió un error al pre-validar el pago"])
			};
		} catch (error) {
			console.error(error)
			return;
		}
		if (!stripe || !elements) {
			// Stripe.js hasn't yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				// Make sure to change this to your payment completion page
				return_url: `${data?.baseDir}/payment/${data?.id}/complete`,
			},
		})
		if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message)
    } else {
      setMessage("An unexpected error occurred.")
    }
    setIsLoading(false)

	}

	const paymentElementOptions = {
    layout: "accordion"
  }

	return (
    <>
			{error && <>
				<Typography color="error.main">{error[1]}</Typography>
				{error[0] === 0 && <Button href={`/web/billing/payment/${data?.id}`}>Regresar</Button>}
			</>}
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <Button variant="contained" sx={{mt:2}} disabled={isLoading || !stripe || !elements || error} id="submit" type="submit">
          <span id="button-text">
            {isLoading ? "Procesando..." : "Confirmar pago"}
          </span>
        </Button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </>
  );
}
export default StripeCheckout