import "./App.css"
import {RouterProvider, createBrowserRouter, redirect} from 'react-router-dom';
import { useContext } from 'react';
import {Context} from "./contexts/general"
import Home from "./modules/home"
import SignIn from "./modules/signin"
import Root from "./modules/root"
import Editor from "./modules/editor";
import List from "./modules/list";
import Temporal from "./modules/temporal"
import Test from "./modules/testing";
import Vectorizer from "./modules/vectorizer";
import Management from "./modules/management";
import Main from "./modules/main";
import SignUp from "./modules/signup";
import ConfirmSignUp from "./modules/confirmSignup";
//import Pricing from "./modules/pricing";
import Register from "./modules/management/register";
import Profile from "./modules/user/profile";
import Config from "./modules/user/config";
import Invoice from "./modules/billing/invoice";
import Invoices from "./modules/billing/invoices";
import Payment from "./modules/billing/payment";
import StripeCheckout from "./modules/billing/payment/stripeCheckout";
import StripeComplete from "./modules/billing/payment/stripeComplete";
import Subscription from "./modules/user/subscription";

const routes = {
	root:"web",
	get: function(route){
		return "/" + this.root + (route ? "/" + route : "")
	}
}

function App() {
	const {auth,unsetAuth} = useContext(Context)
	function homeOnAuth(){
		if(auth) return redirect(routes.get("home"))
		return null
	}
	function requiresAuth(){
		if(!auth) return redirect("/signin")
		return null
	}
	function signout(){
		unsetAuth()
		return redirect("/signin")
	}
	const router = createBrowserRouter([{
		path:"/", element: <Main/>,
		children:[
			{path:"confirm_signup",element:<ConfirmSignUp/>},
			{path:"confirm_tsignup",element:<ConfirmSignUp/>},
			{path:"restore_password",element:<ConfirmSignUp/>},
			{path:"signin",element:<SignIn/>,loader:homeOnAuth},
			{path:"signup",element:<SignUp/>,loader:homeOnAuth},
			{path:"signout",loader:signout},
			{path:"web", element:<Root/>,loader:requiresAuth,children:[
				{path:"signout",loader:signout},
				{path:"billing",children:[
					{path:"invoice/:id",element:<Invoice/>},
					{path:"invoices",element:<Invoices/>,children:[{path:"payLatest"}]},
					{path:"payment/:id",element:<Payment/>,children:[
						{path:"checkout",element:<StripeCheckout/>},
						{path:"complete",element:<StripeComplete/>}
					]}
				]},
				{path:"home",element:<Home/>},
				{path:"create",element:<Editor/>},
				{path:"list",element:<List/>},
				{path:"temp",element:<Temporal/>},
				{path:"vect",element:<Vectorizer/>},
				{path:"management",element:<Management/>},
				{path:"register",element:<Register/>},
				{path:"profile",element:<Profile/>},
				{path:"config",element:<Config/>,children:[
					{path:"subscription",element:<Subscription/>}
				]},
				//{path:"pricing",element:<Pricing/>,loader:requiresAuth},
				{path:"create_temp",element:<Temporal/>},
				{path:"canvas_temp",element:<Temporal/>},
				{path:"test",element:<Test/>}
			]}
		]
	}])
	return <div className="App">
		<RouterProvider router={router}/>
	</div>
}
export default App;
