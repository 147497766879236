import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Context } from "../../contexts/general"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import http from "../../libs/http"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import localizeDate from "../scripts/localizeDate"
import statusList from "./data/invoiceStatus"
import Typography from "@mui/material/Typography"

function Invoices(){
	const location = useLocation()
	const currPath = location.pathname.slice(location.pathname.lastIndexOf("/")+1)
	const [loading,setLoading] = useState(true)
	const [data,setData] = useState()
	const [err,setErr] = useState()
	const {setCsk} = useContext(Context)
	const navigate = useNavigate()
	useEffect(()=>{
		if(loading && !data){
			http.get({url:"subscriptions/pendingInvoices",auth:true}).then(res=>{
				//console.log(res)
				if(res.status === 200){
					setData(res.data)
				}
				setLoading(false)
			})
		}
	},[loading,data])

	function getStatus(key){
		return statusList.get(key)
	}

	async function pay(id){
		setErr()
		http.post({url:"subscriptions/pay",auth:true,body:{inv: id}}).then(res=>{
						
			if(res.status === 200){
				const {_id, stt} = res.data
				if(stt !== 0){
					setLoading(false)
					return navigate(`/web/billing/payment/${_id}`)
				}
				http.post({url: "subscriptions/initStripe",auth:true,body:{_id}}).then(res=>{
					setLoading(false)
					if(res.status === 200 || (res.status === 400 && res.data === "Invalid")) navigate(`/web/billing/payment/${_id}`)
				})
				//navigate(`/web/billing/payment/${res.data._id}`)
			}else if(res.status === 404){
				setErr("No se encontró la factura a pagar")
				setLoading(false)
			}else if(res.status === 400){
				if( res.data === "Already paid") setErr("La factura ya fue pagada")
				else setErr("Ocurrió un problema, inténtalo de nuevo")
				setLoading(false)
			}
		})
	}

	function render(){
		if(data && currPath === "payLatest" && data.length > 0){
			const invoice = data[data.length-1]
			pay(invoice._id)
			return ""
		}
		return <TableContainer sx={{height:"fit-content",td:{textAlign:"left",p:"0.5rem"},th:{textAlign:"left",p:"0.5rem"}}}><Table>
			<TableHead><TableRow>
				<TableCell>Factura</TableCell>
				<TableCell>Estado</TableCell>
				<TableCell>Deuda</TableCell>
				<TableCell>Pagado</TableCell>
				<TableCell>Pagos</TableCell>
				<TableCell>Fecha</TableCell>
				<TableCell>Opciones</TableCell>
			</TableRow></TableHead>
			<TableBody>{data.map((val,idx)=><TableRow key={`inv${idx}`}>
				<TableCell sx={{fontFamily:"monospace"}}>{val._id.toUpperCase()}</TableCell>
				<TableCell sx={{color:val.stt >= 3 ? "success.light" : val.stt >= 0 ? "warning.light" : "error.light"}}>{getStatus(val.stt)}</TableCell>
				<TableCell sx={{fontFamily:"monospace"}}>${val.d$.toFixed(2)}</TableCell>
				<TableCell sx={{fontFamily:"monospace"}}>${val.p$ ? val.p$.toFixed(2) : "0.00"}</TableCell>
				<TableCell>{val.p$s.length}</TableCell>
				<TableCell sx={{fontFamily:"monospace"}}>{localizeDate(val.date," ＠")}</TableCell>
				<TableCell><Button size="small" sx={{p:0}} variant="contained" onClick={()=>{
					setLoading(true)
					pay(val._id)
				}}>Pagar</Button></TableCell>
			</TableRow>)}</TableBody>
		</Table></TableContainer>
	}
	return <Box sx={{p:2,display:"flex",m:"auto",flexDirection:"column"}}>
		<Box component={Paper}>
			{loading && <CircularProgress/>}
			{err && <Typography color="error.main">{err}</Typography>}
			{data && render()}
		</Box>
	</Box>
}
export default Invoices