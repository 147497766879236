import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import { useState } from "react"
import Subscription from "./subscription"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

const pathNames = {
	subscription:{name:"Suscripción"}
}

function Config(){
	const location = useLocation()
	const navigate = useNavigate()
	const currPath = location.pathname.slice(location.pathname.lastIndexOf("/")+1)
	const [currentPath,setCurrentPath] = useState(()=>{
		if(currPath !== "config") return currPath
		return ""
	})

	function render(){
		if(currentPath) return <><Divider/><Outlet/></>
		return <>
			<Divider/>
			<Box sx={{display:"flex",flexDirection:"column",width:"100%"}}>
				<Button onClick={()=>{setCurrentPath("subscription");navigate("subscription")}}>Suscripción</Button>
			</Box>
		</>
	}

	return <Box sx={{p:2,display:"flex",m:"auto",flexDirection:"column"}}>
		<Box component={Paper}>
			<Box>
				<Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} aria-label="breadcrumb" sx={{p:1}}>
					{currentPath ? <Link underline="hover" color="primary" onClick={()=>{setCurrentPath("");navigate("../config")}} sx={{cursor:"pointer"}}>
						Configuración
					</Link> : <Typography sx={{pointerEvents:"none"}}>Configuración</Typography>}
					{currentPath && <Typography>{pathNames[currentPath] ? pathNames[currentPath].name : currentPath}</Typography>}
				</Breadcrumbs>
				{render()}
			</Box>
		</Box>
	</Box>
}

export default Config