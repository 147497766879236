import data from "../.data/server"

class Http{
	constructor(data){
		if(data.url) this.url = data.url
		else this.url = ""
		if(data.protocol) this.protocol = data.protocol
	}
	async post({url,uri,body,headers,auth,getHeaders}){
		if(uri) url = this.protocol + uri + "/" + url
		else url = this.url + url
		try {
			let res = await fetch(getRequest(url,1,{body,headers,auth})).then()
			console.log(res)
			let ct = res.headers.get("content-type"), _headers
			/*console.log()
			let it = res.headers.entries()
			let next = it.next()
			while(next){
				console.log(next)
				if(next.value === undefined) break
				next = it.next()
			}*/
			if(getHeaders){
				_headers = {}
				for(let header of getHeaders){
					_headers[header] = res.headers.get(header)
				}
			}
			body = null
			if(ct){
				if(ct.includes("json")) body = await res.json().then()
				else if(ct.includes("text")) body = await res.text().then()
				else if(ct.includes("image")) body = await res.blob().then()
				else if(ct === "application/octet-stream") body = await res.blob().then()
			}
			const rslt = {status: res.status, statusText: res.statusText, data: body}
			if(res.status === 403 && (body?.msg === "IP Mismatch" || body?.msg === "Invalid session")) window.location.href = "/signout"
			if(getHeaders) rslt.headers = _headers
			return rslt
		} catch (error) {
			return {status: 500, error}
		}
	}
	async get({url,query,auth}){
		console.log("getting url:",url)
		url = this.url + url
		//console.log({url})
		let res = await fetch(getRequest(url,0,{auth})).then(), data
		let ct = res.headers.get("content-type")
		if(ct){
			if(ct.includes("json")) data = await res.json().then()
			else if(ct.includes("text")) data = await res.text().then()
		}
		if(res.status === 403 && (data?.msg === "IP Mismatch" || data?.msg === "Invalid session")) window.location.href = "/signout"
		const rslt = {status: res.status, statusText: res.statusText, data}
		return rslt
	}
}

function getRequest(url,method,{body,headers,auth}){
	let init = {
		method: method === 1 ? "post" : "get"
	}
	if(body){
		if(body instanceof FormData){
			init.body = body
		}else if(typeof body === "string"){
			init.headers = {"Content-Type": "text/html; charset=utf-8"}
			init.body = body
		}else{
			init.headers = {"Content-Type": "application/json"}
			init.body = JSON.stringify(body)
		}
	}
	if(auth){
		if(auth === true) init.credentials = "same-origin"
		else{
			if(!init.headers) init.headers = {}
			init.headers.Authorization = auth
		}
	}
	if(headers) init.headers = {...init.headers,...headers}
	return new Request(url,init)
}

const http = new Http(data)

export default http;