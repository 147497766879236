import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import Link from '@mui/material/Link'
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { Context } from "../contexts/general";
import { useContext, useEffect, useState } from "react";
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CircleIcon from '@mui/icons-material/Circle';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import http from "../libs/http"
import { CircularProgress } from "@mui/material"
import { getCookie } from "./scripts/cookie"
import { useNavigate } from "react-router-dom"

const pathList = {
	news: {text:"Noticias",ntfCb:()=>{return true},render:()=>{
		return <Box sx={{textAlign:"justify",textJustify:"inter-word"}}>
			<Typography>Hemos actualizado la lista de planes de suscripción para utilizar ArmaloStudio correctamente.</Typography><br/>
			<Typography>Si eres un nuevo usuario, por favor revisa la pestaña de suscripción o ve a la configuración para revisar el estado y la lista de planes disponibles y selecciona uno para poder continuar utlizando el sistema. Recibirás una prueba gratuita de 5 días.</Typography><br/>
			<Typography>Si te registraste en los últimos días de la prueba gratuita la suscripción se habilitó automáticamente y es posible que te queden algunos días de uso gratuito.</Typography>
		</Box>
	}},
	subscription: {text:"Suscripción",
		ntfCb:(data)=>{
			if(data.mpq > 0) return true
			return false
		},
		render:(data,loading,navigate)=>{
			if(loading) return <CircularProgress sx={{m:"auto"}}/>
			let elem
			console.log({data})
			if(data === null){
				elem = <><Paper elevation={0} sx={{backgroundColor:"info.main",p:1,color:"white",width:"100%",boxSizing:"border-box"}}><ErrorOutlineIcon sx={{fontSize:{xs:"2rem",md:"2.75rem"}}}/><Typography fontWeight={500}>Aún no te has suscrito, ingresa a la sección de suscripciones para iniciar el proceso de suscripción</Typography>
				</Paper><Button color="info" variant="contained" sx={{mt:2}} href="config/subscription">Ir a suscripción</Button></>
			}else if(data?.mpq > 0){
				const now = new Date()
				const cAt = new Date(data.cAt)
				const diff = now - cAt
				if(diff < 2678400000 && !data.sAt){
					//elem = <><Paper elevation={0} sx={{width:"100%",boxSizing:"border-box",backgroundColor:"error.main",p:1,color:"white"}}><ErrorOutlineIcon sx={{fontSize:{xs:"2rem",md:"2.75rem"}}}/><Typography fontWeight={500}>Tu suscripción tiene {data.mpq} pago(s) pendiente(s) y se encuentra suspendida<br/>¡NOTA!: Puedes cambiar tu plan de suscripción si quieres mejores beneficios, revisa la parte inferior de la sección de tu suscripción.</Typography></Paper>{/*<Button color="error" variant="contained" sx={{mt:2}} href="billing/invoices">Revisar facturas</Button>*/}<Button variant="contained" sx={{mt:2}} href="config/subscription">Ver suscripción</Button></>
					elem = <><Paper elevation={0} sx={{width:"100%",boxSizing:"border-box",backgroundColor:"error.main",p:1,color:"white"}}><ErrorOutlineIcon sx={{fontSize:{xs:"2rem",md:"2.75rem"}}}/><Typography fontWeight={500}>Tu prueba gratuita de Armalo Studio finalizó.<br/>¡Elige un plan y continúa!</Typography></Paper><Button variant="contained" sx={{mt:2}} href="config/subscription">Elegir plan</Button></>
				}else{
					elem = <><Paper elevation={0} sx={{width:"100%",boxSizing:"border-box",backgroundColor:"error.main",p:1,color:"white"}}><ErrorOutlineIcon sx={{fontSize:{xs:"2rem",md:"2.75rem"}}}/><Typography fontWeight={500}>Lo sentimos, tu suscripción se encuentra suspendida.<br/>¡Renueva tu pago para continuar!</Typography></Paper><Box sx={{"> button":{mt:2,mx:1}}}><Button variant="outlined" onClick={()=>{
						navigate("../config/subscription")
					}}>Ver detalles</Button><Button variant="contained" onClick={()=>navigate("../billing/invoices/payLatest")}>Pagar ahora</Button></Box></>
				}
				
			}else if(data?.mpq === 0){
				elem = <>
					{data.stt === 0 && <Paper elevation={0} sx={{mb:2,width:"100%",boxSizing:"border-box",backgroundColor:"warning.main",p:1,color:"white"}}><ErrorOutlineIcon sx={{fontSize:{xs:"2rem",md:"2.75rem"}}}/><Typography fontWeight={500}>Has cancelado tu plan pero podrás usarlo hasta la fecha de corte</Typography></Paper>}
					<Typography>Los pagos de la suscripción están al día</Typography><Button href="config/subscription" variant="contained" sx={{mt:2}}>Ver detalles</Button>
				</>
			}
			return <Box sx={{width:"inherit", display:"flex",flexDirection:"column",alignItems:"center"}}>
				{elem}
			</Box>
		}
	}
}

function Home(){
	const navigate = useNavigate()
	const {userInfo} = useContext(Context)
	const [loading,setLoading] = useState(true)
	const [paths,setPaths] = useState([])
	const [sub,setSub] = useState()
	const data={subscription:sub,news:1}
	useEffect(()=>{
		if(loading && !sub){
			http.get({url:"subscriptions/get",auth:true}).then(res=>{
				if(res.status === 200){
					const data = res.data
					const now = new Date(), cAt = new Date(data.cAt), npd = new Date(data.npd)
					if(data && ((data.stt >= 1 && data.mpq === 0) || (data.stt === 0 && data.mpq === 0 && now < data.npd))) navigate("/web/create")
					if(!isNaN(npd)){
						const diff = now - cAt
						if((diff < 2678400000 && !data.sAt) || data.mpq === 1){
							setPaths(["subscription"])
						}
					}
					setSub(data)
				}else if(res.status === 404) setSub(null)

				setLoading(false)
			})
		}
	},[loading, navigate, sub])

	function renderPathList(){
		const pathElems = []
		let idx = 0
		for(let path in pathList){
			let notify = false
			const val = pathList[path]
			if(val.ntfCb && data[path]) notify = val.ntfCb(data[path])
			const isSelected = paths[0] === path
			pathElems.push(<Box key={`path${idx++}`} sx={{backgroundColor: isSelected ? "grey.200" : undefined, color: isSelected ? "primary" : undefined}}>
				<ListItemButton onClick={()=>setPaths([path])} disabled={loading}>
					<ListItemText primary={val.text} sx={{mr:1}}/>
					{notify ? <ErrorIcon sx={{color:"error.light"}}/> : <RadioButtonUncheckedIcon sx={{color: isSelected ? "grey.400" :"grey.300"}}/>}
				</ListItemButton>
			<Divider/></Box>)
		}
		return pathElems
	}
	return <Box sx={{width:"100%",height:"100%",display:"flex",justifyContent:"center",alignContent:"center"}}>
		<Box component={Paper} sx={{mb:2,mx:2,mt:1,width:"inherit",overflow:"hidden"}} elevation={3}>
		<Box sx={{height:"100%",dispay:"flex",flexDirection:"column"}}>
			<Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} sx={{p:1}}>
				{paths.length === 0 ? <Typography sx={{pointerEvents:"none"}}>Inicio</Typography>:  <Link underline="hover" onClick={()=>{setPaths([])}} color="primary" sx={{cursor:"pointer"}}>Inicio</Link>}
				{paths.length > 0 && paths.map((val,idx)=>{
					return <Typography key={`bcPath${idx}`} sx={{pointerEvents:"none"}}>{pathList[val].text}</Typography>
				})}
			</Breadcrumbs>
			<Divider/>
			<Box sx={{display:"flex",flexDirection:"row",height:"inherit"}}>
				<List disablePadding sx={{"& div.MuiListItemButton-root":{maxHeight:"2.5rem"}}}>
					{renderPathList()}
				</List>
				<Divider orientation="vertical" flexItem/>
				<Box sx={{height:"calc(100% - 3.5rem)",overflow:"auto",p:1,width:"100%"}}>
					{paths.length === 0 && <Typography>Bienvenido a ArmaloStudio</Typography>}
					{paths.length > 0 && pathList[paths[0]].render && pathList[paths[0]].render(sub,loading,navigate)}
				</Box>
			</Box>
		</Box>
	</Box></Box>
}
export default Home;