import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid2"
import DeleteIcon from "@mui/icons-material/Delete"
import SyncIcon from "@mui/icons-material/Sync"
import SettingsIcon from "@mui/icons-material/Settings"
import DownloadIcon from "@mui/icons-material/Download"
import ListIcon from "@mui/icons-material/ViewList"
import ModuleIcon from "@mui/icons-material/ViewAgenda"
import Pagination from "@mui/material/Pagination"
import CircularProgress from "@mui/material/CircularProgress"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import Paper from "@mui/material/Paper"
import http from "../../libs/http"
import localizeDate from "../scripts/localizeDate"
import ms from "../constants/measurement"
import CanvasRow from "./canvasRow"
import { Context } from "../../contexts/general";

function HorizontalDivider(){
	return <Divider sx={{width:"calc(100% + 1rem)",marginLeft:"-0.5rem"}}/>
}

function CanvasList(){
	/**
	 * @type {[Array<{id: Number, name: String, description: String, w: Number, h: Number, mode: Number, state: Number, create_date: Date, write_date: Date}>,Function]}
	 */
	const [list,setList] = useState([])
	const [loading,setLoading] = useState(true)
	const [error,setError] = useState()
	const [page,setPage] = useState(0)
	const elemsPerPage = 20
	const [view,setView] = useState(0)
	const {auth} = useContext(Context)
	useEffect(()=>{
		if(loading){
			/*let i = 0
			while(i<50){i++;list.push({id:i})}
			setLoading(false)
			return*/
			http.get({url:"rectpack/list_canvas",auth}).then(res=>{
				console.log(res)
				console.log(res.data)
				if(res.status === 200) setList(res.data)
				else if(res.status === 404) setError([404,"No se encontraron resultados"])
				else if(res.status === 500){
					if(res.error.message === "Failed to fetch") setError(["","No se pudo conectar con el servidor"])
					else setError([500,res.error.message])
				}
				setLoading(false)
			})
			return
		}
	})
	const nullText = {opacity:0.54, userSelect:"none"}
	const onDelete = (_id,idx) =>{
		http.post({url:"rectpack/remove",body:{_id},auth}).then(res=>{
			if(res.status === 404 || res.status === 200){
				list.splice(idx,1)
				setList([...list])
			}
		})
	}
	function getStateStr(state){
		let vals = state === 0 ? ["warning","Pendiente"] : ["success","Finalizado"]
		return <Typography sx={(theme)=>({color: theme.palette[vals[0]].light})} component="b" fontWeight={500}>{vals[1]}</Typography>
	}
	const noName = <Typography sx={nullText} component="i">Sin Nombre</Typography>
	const noDesc = <Typography sx={nullText} component="i">Sin Desc.</Typography>
	const sticky = {position:"sticky", left: 0, bgcolor: "white", zIndex:1}
	function renderItems(subset){
		if(view === 0) return <TableContainer component={Paper} sx={{width:"auto",mx:"0.5rem",td:{textAlign:"center",p:"0.5rem"},th:{textAlign:"center",p:"0.5rem"}}}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell sx={{position:"relative"}} colSpan={11}>Lista de trabajos<IconButton sx={{position:"absolute",right:0,top:"0.2rem"}} size="small" color="primary"><SettingsIcon/></IconButton></TableCell>
					</TableRow>
					<TableRow sx={{position:"sticky", top:0, bgcolor:"white",zIndex:1}}>
						<TableCell sx={sticky}>Trabajo</TableCell>
						<TableCell>Opciones</TableCell>
						<TableCell>Nombre</TableCell>
						<TableCell>Descripción</TableCell>
						<TableCell>Usuario/Teléfono</TableCell>
						<TableCell>Estado</TableCell>
						<TableCell>Modo</TableCell>
						<TableCell>Ancho (cm)</TableCell>
						<TableCell>Alto (cm)</TableCell>
						<TableCell>Creado</TableCell>
						<TableCell>Modificado</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
				{subset.map((val,idx)=>(
					<CanvasRow key={`trow${idx}`}
						val={val} idx={idx} sticky={sticky}
						getStateStr={getStateStr}
						onDelete={()=>{onDelete(val._id,idx+(page*elemsPerPage))}}
						onUpdate={(idx,type)=>{
							let item = list[idx+(page*elemsPerPage)]
							item.state = type
							setList([...list])
						}}
						noName={noName} noDesc={noDesc} />
				))}
				</TableBody>
			</Table>
		</TableContainer>
		return subset.map((val,idx)=>{
			return <Box sx={{boxShadow:2,borderRadius:2, bgcolor:"#fff",mx:"2rem",my:"1rem", display:"flex", flexDirection:"row"}} key={"item"+idx}>
				<Box sx={{p:1,display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
					<Box sx={{display:"flex",alignItems:"center",
					justifyContent:"center","& img":{maxHeight:"100%"}}}
					height="64px" width="70px">
						<img src={`${http.url}rectpack/canvas_img/${val._id}?web=1`} alt="Failed to load"/>
					</Box>
					<Button sx={{width:70,p:"3px",display:"flex",flexDirection:"column",borderRadius:2}} variant="text" href={`${http.url}rectpack/canvas_img/${val._id}`}><DownloadIcon/><Typography fontSize={12} fontWeight={500}>Descargar</Typography></Button>
				</Box>
				<Divider orientation="vertical" flexItem/>
				<Box width="100%" sx={{overflow:"hidden",textAlign:"left", p:1, position:"relative"}}>
					<IconButton sx={{position:"absolute",top:"-0.25rem",right:"-0.25rem"}} value={val._id} onClick={()=>{onDelete(val._id,idx+(page*elemsPerPage))}}><DeleteIcon/></IconButton>
					<Typography component="b" fontWeight={500}>ID: </Typography><Typography component="span" color="primary">{val._id}</Typography>
					<HorizontalDivider/>
					<Grid container>
						<Grid xs={12}>
							<Typography component="b" fontWeight={500}>Nombre: </Typography>
							{val.name ? val.name : <Typography sx={nullText} component="i">Sin Nombre</Typography>}
						</Grid>
						<Grid xs={12}>
							<Typography component="b" fontWeight={500}>Descripción: </Typography>
							{val.desc ? val.desc : <Typography sx={nullText} component="i">Sin Desc.</Typography>}
						</Grid>
					</Grid>
					<HorizontalDivider/>
					<Grid container spacing={1}>
						<Grid xs={6}>
							<Typography component="b" fontWeight={500}>Estado: </Typography>{getStateStr(val.state)}
						</Grid>
						<Grid xs={6}>
							<Typography component="b" fontWeight={500}>Modo: </Typography>
							<Typography color="primary" component="b">{val.mode === 0 ? "Múltiples imágenes" : "Única imágen repetida"}</Typography></Grid>
					</Grid>
					<HorizontalDivider/>
					<Grid container spacing={1}>
						<Grid xs={12} sm={2}>
							<Typography component="b" fontWeight={500}>Ancho: </Typography>
						</Grid>
						<Grid xs={12} sm={4}>
							<Typography color="dimgray" component="b">{(val.w * ms.pxToCm).toFixed(4)} cm</Typography>
						</Grid>
						<Grid xs={12} sm={2}>
							<Typography component="b" fontWeight={500}>Alto: </Typography>
						</Grid>
						<Grid xs={12} sm={4}>
							<Typography color="dimgray" component="b">{(val.h * ms.pxToCm).toFixed(4)} cm</Typography>
						</Grid>
					</Grid>
					<HorizontalDivider/>
					<Grid container spacing={1}>
						<Grid xs={12} sm={2}>
							<Typography component="span" fontWeight={500}>Creado el: </Typography>
						</Grid>
						<Grid xs={12} sm={4}>
							<Typography color="dimgray" component="b">{localizeDate(val.cAt)}</Typography>
						</Grid>
						<Grid xs={12} sm={2} sx={{overflow:"hidden"}}>
							<Typography component="span" fontWeight={500}>Modificado el: </Typography>
						</Grid>
						<Grid xs={12} sm={4}>
							<Typography color="dimgray" component="b">{localizeDate(val.wAt ? val.wAt : val.cAt)}</Typography>
						</Grid>
					</Grid>
				</Box>
			</Box>
		})
	}
	function renderList(){
		const subset = list.slice(page*elemsPerPage,(page+1)*elemsPerPage)
		if(!subset || subset.length === 0) return
		if(!Array.isArray(subset)){
			if(!error) setError(["","Error al interpretar datos"])
			return
		}
		const count = Math.ceil(list.length / elemsPerPage)
		const doPaginate = list.length > elemsPerPage
		return <Box sx={{height:"100%",width:"100%"}}>
			<Box sx={{overflow:"auto",boxSizing:"border-box",position:"relative",height:`calc(100% - ${doPaginate ? "2.4rem" : "0px"})`}}>
				<div>
					<IconButton color={view === 0 ? "primary": "auto"} onClick={()=>{setView(0)}}><ListIcon/></IconButton>
					<IconButton color={view === 1 ? "primary" : "auto"} onClick={()=>{setView(1)}}><ModuleIcon/></IconButton>
				</div>
				{renderItems(subset)}
			</Box>
			{doPaginate && <Pagination sx={{position:"sticky",display:"flex",justifyContent:"center",width:"100%",height:"2.4rem",bgcolor:"white",boxSizing:"border-box",borderTop:"1px solid rgba(0,0,0,0.2)",boxShadow:"0 0.6rem 1rem 1px"}} count={count} color="primary" page={page+1} onChange={(ev,val)=>{setPage(val-1)}}/>}
		</Box>
	}

	return <Box sx={{height:"100%",width:"100%",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
		{loading && <CircularProgress sx={{my:"auto"}}/>}
		{list && renderList()}
		{error && <Box sx={{color:"rgba(0,0,0,0.54)"}}><Typography>Error {error[0]}</Typography><Typography>{error[1]}</Typography><IconButton onClick={()=>{setLoading(true);setError()}}><SyncIcon/></IconButton></Box>}
	</Box>
}

export default CanvasList;