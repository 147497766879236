import { useContext, useEffect, useState } from "react"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import CircleIcon from '@mui/icons-material/Circle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import http from "../../../libs/http"
import localizeDate from "../../scripts/localizeDate"
import { Button, Chip, CircularProgress, Collapse, IconButton, List, ListItem, ListItemIcon, Typography } from "@mui/material"
import Grid from "@mui/material/Grid2"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import { Context } from "../../../contexts/general"

const statusList = new Map([[-2,"Cancelada"],[-1,"Pago expirado"],[0,"Inactivo"],[1,"Verificando pago"],[2,"Activo"]])

function Subscription(){
	const [data,setData] = useState()
	const [seeList,setSeeList] = useState(false)
	const [subs,setSubs] = useState()
	const [loading,setLoading] = useState(true)
	const [subscribing,setSubscribing] = useState(false)
	const [error,setError] = useState()
	const [openDscl,setOpenDscl] = useState(false)
	const navigate = useNavigate()
	const {userInfo} = useContext(Context)
	useEffect(()=>{
		if(loading && data === undefined){
			setData(0)
			http.get({url:"subscriptions/get",auth:true}).then(res=>{
				const data = res.data
				console.log("sub/get")
				if(res.status === 200){
					const now = new Date()
					data.npd = new Date(data.npd)
					data.cAt = new Date(data.cAt)
					//console.log({sAt: data.sAt, diff: now - data.cAt, subs})
					if(!data.sAt/* || (now - data.cAt < (data.pid?.free ? data.pid.free : 432000000))*/){
						setSeeList(true)
					}
					if(data.sAt) data.sAt = new Date(data.sAt)
					setData(data)
				}
				if(res.status === 404){
					console.log("setting data to null")
					setData(null)
				}
				if(subs !== 0 && !isNaN(data?.sAt)) setLoading(false)
			})
			return
		}
		if(loading && subs === undefined){
			if(data === 0) return
			setSubs(0)
			http.post({url:"subscriptions/list"}).then(res=>{
				console.log({data})
				if(data !== 0) setLoading(false)
				if(res.status === 200) setSubs(res.data)
			})
		}
	},[loading,data,subs,seeList])

	function getStatus(key){
		return statusList.get(key)
	}
	function subscribe(){
		setLoading(true)
		setData(true)
		http.post({url:"subscriptions/list"}).then(res=>{
			setLoading(false)
			if(res.status === 200) setSubs(res.data)
			setData()
		})
	}
	function renderDescList(){
		if(!data || !data.pid) return
		let dscl = data.pid.dscl
		if(!dscl) return
		dscl = [...dscl]
		if(dscl.length === 0) return
		if(dscl.length === 1) return dscl[0]
		const ni = dscl.shift()
		return <List sx={{p:0,"> *":{p:"0 !important"}}}>
			<ListItem sx={{"> *":{p:"0 !important",justifyContent:"space-between"}}}>
				<ListItemIcon>
					<CircleIcon sx={{fontSize:"0.5rem"}}/>
				</ListItemIcon>
				<div>{ni}</div>
			</ListItem>
			<Collapse sx={{"> *":{p:"0 !important"}}} in={openDscl} timeout="auto" unmountOnExit>
				<List sx={{p:0,"> *":{p:"0 !important"}}}>{dscl.map((val,idx)=><ListItem key={`dscli${idx}`}>
				<ListItemIcon>
					<CircleIcon sx={{fontSize:"0.5rem"}}/>
				</ListItemIcon>
					{val}
				</ListItem>)}
				</List>
			</Collapse>
		</List>
	}
	function renderSubList(){
		const now = new Date(), cAt = data ? new Date(data.cAt) : undefined, sAt = data?.sAt ? new Date(data.sAt) : undefined
		return <><Typography variant="h4" paddingTop={1} fontWeight={600}>¡Precios únicos de lanzamiento!</Typography>
			<Typography variant="h5" paddingTop={1} fontWeight={600}>Elige el plan adecuado para tu negocio</Typography>
			<Grid container spacing={2} sx={{p:1}}>
			{subs.map((val,idx)=>{
				const dp$ = val.p$ * (val["p$d%"] ? 1 - val["p$d%"] : 1)
				const p$t = val.p$t
				let btn
				if(!seeList){
					btn = <Button sx={{mb:2}} variant="contained" size="large" disabled={subscribing !== false} onClick={()=>{subscribeCb(val)}}>{subscribing === val._id && <CircularProgress size="30px" sx={{position:"absolute"}}/>}Suscribirse</Button>
				}else if(data && seeList){
					console.log(data)
					console.log({sAt})
					if(!sAt || (now - cAt < (data.pid?.free ? data.pid.free : 432000000))){
						btn = <Button sx={{mb:2}} variant="contained" size="large" disabled={subscribing !== false} onClick={()=>{
							confirmSwapSub(val)
						}}>{subscribing === val._id && <CircularProgress size="30px" sx={{position:"absolute"}}/>}Elegir plan</Button>
					}else{
						btn = <Button sx={{mb:2}} variant="contained" size="large" disabled={subscribing !== false || val._id === data.pid._id} onClick={()=>{
							if(val._id === data.pid._id) return
							confirmSwapSub(val)
						}}>{subscribing === val._id && <CircularProgress size="30px" sx={{position:"absolute"}}/>}{val._id === data.pid._id ? "Actual" : "Cambiar"}</Button>
					}
				}
				return <Grid size={{xs:12,md:4}} key={`sub${idx}`} sx={{p:2}}>
				<Box component={Paper} height="100%" sx={
				{overflow:"hidden", boxShadow:4, "ul":{textAlign:"left",mx:6, "li":{color: p$t === "y" ? "white":"primary.main"}},display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"space-between",backgroundColor: p$t === "y" ? "primary.light" : undefined, color: p$t === "y" ? "white !important" : undefined}}>
				<Box width="100%">
				<Typography variant="h4" fontWeight={800} sx={{py:1,mb:1,width:"100%"}}>{val.name}</Typography>
				<Typography variant="h6" color={p$t === "y" ? "white" : "rgba(0,0,0,0.66)"}>{val.desc}</Typography>
				<Box sx={{display:"flex",mx:"auto",width:"fit-content",alignItems:"baseline",my:1}}><Typography variant="h2" fontWeight={800}>${getPriceStr(dp$,val.p$t)}</Typography><Typography fontWeight={800} variant="h5">/mes</Typography></Box>
				{val.p$t === "y" && <Box sx={{position:"relative",width:"fit-content",p:1.5,mx:"auto"}}>{val["p$d%"] && <Typography fontWeight={500} color={/*"lightGreen.A200"*/"white"} sx={{position:"absolute",right:"2px",top:"-1px", textAlign:"end"}}>-{val["p$d%"]*100}% off</Typography>}<Typography variant="h6" fontWeight={500} color="white">${dp$.toFixed(2)}/año</Typography></Box>}
				{Array.isArray(val.dscl) && <ul>
					{val.dscl.map((val,idx)=><li key={`dscl${idx}`}><Typography variant="h6" color={p$t === "y" ? "white" : "rgba(0,0,0,0.66)"}>{val}</Typography></li>)}
				</ul>}
				</Box>
				{btn}
				{/*!seeList && <Button sx={{mb:2}} variant="contained" size="large" disabled={subscribing !== false} onClick={()=>{subscribeCb(val)}}>{subscribing === val._id && <CircularProgress size="30px" sx={{position:"absolute"}}/>}Suscribirse</Button>*/}
				{/*data && seeList && <Button sx={{mb:2}} variant="contained" size="large" disabled={subscribing !== false || val._id === data.pid._id} onClick={()=>{
					if(val._id === data.pid._id) return
					confirmSwapSub(val)
				}
				}>{subscribing === val._id && <CircularProgress size="30px" sx={{position:"absolute"}}/>}{val._id === data.pid._id ? "Actual" : "Cambiar"}</Button>*/}
			</Box></Grid>})}
		</Grid>
		</>
	}
	if(loading) return <CircularProgress sx={{mx:"auto",my:2}}/>
	function getPriceStr(p$,p$t){
		if(p$t === "y") return (p$/12).toFixed(2)
		return p$.toFixed(2)
	}
	const subscribeCb = (val)=>{
		setSubscribing(val._id)
		setError()
		http.post({url:"subscriptions/activate",auth:true,body:{_id:val._id}}).then(res=>{
			if(res.status === 200){
				setData()
				setSubs()
				setLoading(true)
				setSubscribing(false)
			}else{
				setSubscribing(false)
				setError("Ocurrió un problema, inténtalo de nuevo")
			}
		})
	
	}
	const cancelSub = ()=>{
		setLoading(true)
		setError()
		http.post({url:"subscriptions/deactivate",auth:true}).then(res=>{
			setLoading(false)
			if(res.status === 200){
				return setData(data=>{
					data.stt = 0
					return {...data}
				})
			}
			setError("Ocurrió un problema, inténtalo de nuevo")
		})
	}
	const resumeSub = () => {
		setLoading(true)
		setError()
		http.post({url:"subscriptions/resume",auth:true}).then(res=>{
			setLoading(false)
			if(res.status === 200){
				return setData(data=>{
					data.npd = res.data.npd
					data.stt = res.data.stt
					data.mpq = res.data.mpq
					return {...data}
				})
			}
			setError("Ocurrió un problema, inténtalo de nuevo")
		})
	}
	const swapSub = ()=>{
		setSeeList(true)
		if(!subs) setLoading(true)
	}
	const confirmSwapSub = (val)=>{
		setLoading(true)
		http.post({url:"subscriptions/swap",auth:true,body:{_id: val._id}}).then(res=>{
			setLoading(false)
			if(res.status === 200){
				console.log({data: res.data})
				const data = res.data
				data.pid = val
				setData(data)
				setSeeList(false)
			}else if(res.status === 400 && res.data === "Already set"){
				setData(data=>{
					data.sAt = new Date()
					return {...data}
				})
				setSeeList(false)
			}
		})
	}
	if(data === null) return <Box sx={{m:2}}>
		<Typography>No cuentas con una suscripción activa, pero puedes suscribirte con el botón de abajo</Typography>
		<Button variant="contained" sx={{mt:2}} onClick={subscribe}>Activar suscripción</Button>
	</Box>
	const now = new Date()
	const remainingPaymentTime = data ? data.npd - now : undefined
	//console.log({remainingPaymentTime})
	function getCost(){
		const base = data.pid
		let d$ = base.p$
		if(base["p$d%"]) d$ *= (1-base["p$d%"])
		const suffix = base.p$t === "m" ? "/mes" : "/año"
		return `${d$.toFixed(2)} ${suffix}`
	}
	return <Box sx={{my:2,display:"flex",flexDirection:"column"}}>
		{subs && (seeList || !data) && renderSubList()}
		{data && !seeList && <TableContainer component={Paper} sx={{boxShadow:2,width:"calc(100% - 2rem)",height:"fit-content",mx:"1rem",td:{textAlign:"left",p:"0.5rem"},th:{textAlign:"left",p:"0.5rem"}}}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell colSpan={2}><Typography variant="h6" fontWeight={600} align="center">Estado de la suscripción</Typography></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell component="th" variant="head">Nombre</TableCell>
						<TableCell>{data.pid.name}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell component="th" variant="head">Descripción</TableCell>
						<TableCell>{data.pid.desc}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell component="th" variant="head" sx={{verticalAlign:"baseline"}}><Box sx={{display:"flex",justifyContent:"space-between"}}><div>Funciones</div>{data?.pid?.dscl?.length > 1 && <IconButton sx={{p:0}} size="small" onClick={()=>{setOpenDscl(cond=>!cond)}}>{openDscl ? <ExpandLess/> : <ExpandMore/>}</IconButton>}</Box></TableCell>
						<TableCell>{renderDescList()}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell component="th" variant="head">Costo</TableCell>
						<TableCell>${getCost()}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell component="th" variant="head">Estado</TableCell>
						<TableCell sx={{color:data.stt >= 2 ? "success.light" : data.stt >= 0 ? "warning.light" : "error.light"}}>{getStatus(data.stt)}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell component="th" variant="head">Fecha del próximo pago</TableCell>
						<TableCell sx={{color: remainingPaymentTime < -86400000 ? "error.light" : remainingPaymentTime < 0 ? "warning.light" : undefined}}>{localizeDate(data.npd)}
							{remainingPaymentTime < -86400000 ? <Chip label="expiró" color="error" size="small" sx={{ml:1}}/> : remainingPaymentTime < 0 && <Chip label="pendiente" color="warning" size="small" sx={{ml:1}}/>}
						</TableCell>
					</TableRow>
					{data.mpq > 0 && <TableRow>
						<TableCell component="th" variant="head" sx={{verticalAlign:"baseline"}}><Box sx={{display:"flex",justifyContent:"space-between"}}><div>Pagos pendientes</div><Button variant="text" size="small" onClick={()=>{navigate("/web/billing/invoices")}} startIcon={<VisibilityIcon/>}>Ver pagos</Button></Box></TableCell>
						<TableCell>{data.mpq}</TableCell>
					</TableRow>}
				</TableBody>
			</Table>
		</TableContainer>}
		{data && <Box sx={{mx:2,pt:2,display:"flex",flexDirection:"row"}}>
			{seeList && <Button sx={{ml:"auto"}} onClick={()=>setSeeList(false)}>Regresar</Button>}
			{!seeList && <>
			<Button disabled={loading} onClick={data.stt === 0 ? resumeSub : cancelSub}>{data.stt === 0 ? "Reanudar Suscripción" : "Cancelar Suscripción"}</Button><Button sx={{ml:0}} disabled={loading || data.stt > 0 || (data.stt === 0 && data.mpq === 0)} onClick={swapSub}>{loading && <CircularProgress size="30px" sx={{mr:"auto",position:"absolute"}}/>}Cambiar Suscripción</Button>
			{data.mpq > 0 && <Button sx={{ml:"auto",mr:0}} variant="contained" onClick={()=>navigate("/web/billing/invoices/payLatest")}>Pagar ahora</Button>}
			</>}
		</Box>}
	</Box>
}

export default Subscription