import moment from "moment"

const validKeys = ["hide-campaign","news0","all-bgremover-methods"]

function getCookie(key){
	if(!validKeys.includes(key)) return
	console.log(document.cookie)
	let b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)")
	return b ? b.pop() : ""
}
function setCookie(key,value,path=""){
	if(!validKeys.includes(key)) return
	const date = new Date()
	date.setTime(date.getTime() + 31556952000)
	document.cookie = `${key}=${value}; expires=${moment(date.getTime()).format("ddd, DD MMM yyyy h:mm:ss [GMT]")}; path=/${path}`
}
function deleteCookie(key,value){
	if(!validKeys.includes(key)) return
	document.cookie = `${key}="";max-age=-1`
}
export {setCookie,deleteCookie,getCookie}